const strings: any =
{
  views: {
    gettingStarted: {
      addFileUrlDesktop: "https://vimeo.com/868833658",
      addFileUrlMobile: "https://vimeo.com/868833702",
      addFilesHeader: "Adding files",
      addFilesContent: "After Mylio Photos is installed on your main device, it’s time to start adding photos and videos to your Mylio Photos library.",
      filterSmarttagUrl: "https://vimeo.com/868833742",
      filterSmarttagHeader: " Find things faster with QuickFilters & SmartTags",
      filterSmarttagContent: "Quickly find your best photos and rediscover forgotten memories with Mylio Photos’ QuickFilters and AI-powered SmartTags.",
      editPhotosUrl: "https://vimeo.com/868833840",
      editPhotosHeader: "Enhancing your photos",
      editPhotosContent: "Learn how to perform essential editing tasks to bring out the best of your images while preserving your original files. Use presets to improve color and tone. Use crop and rotation tools to improve composition.",
      shareExportUrl: "https://vimeo.com/868833951",
      shareExportHeader: "Sharing your photos",
      shareExportContent: "Easily share files with your family, friends, and contacts. Export with custom size, resolution, and metadata for use in other applications.",
      backupUrl: "https://vimeo.com/868834054",
      backupHeader: "Protecting your photos and videos with Mylio Photos+",
      backupContent: "You can easily share and export photos from your Mylio Photos account to have access to the images outside of the application. This includes photo sharing with others from desktop or mobile devices."
    },
    plansView: {
      bHeaderOne: "A Universal Library",
      bContentOne: "Mylio Photos+ is a complete solution where you can connect your computer, phone, tablet, and hard drives to a single photo library.",
      bHeaderTwo: "Protect what matters most.",
      bContentTwo: "Never lose a photo, quickly find your important memories, and easily share with those you love. Mylio Photos+ is an all-in-one ecosystem for your family's photo and video archive. It's the only plan you need to organize and protect all your family memories.",
      bHeaderThree: "Own your photos.",
      bContentThree: "Mylio Photos+ creates local backup on hard drives you own. It’s the only complete solution that offers Cloud independence. 8x faster and 8x more affordable than Cloud storage.",
      bHeaderFour: "Never lose an important photo or video.",
      bContentFour: "Add as many vaults as you need for extra peace of mind. Lose your phone? Hard drive failure? Mylio Photos+ can recover all your images and rebuild your photo library on a new device automatically.",
      bHeaderFive: "No scaling costs. Total privacy.",
      bContentFive: "The more photos you take, the more Cloud storage costs go up. With Mylio Photos+, you avoid traditional Cloud services' rising costs and privacy issues.",
      bHeaderSix: "Worry-Free Backup.",
      bContentSix: "Secure your Mylio Photos Library by designating any device as a Vault to protect your data. Stop worrying about losing a device or catastrophic disk failures.",
      bHeaderSeven: "Universal Library",
      bContentSeven: "Connect all your computers, tablets, and phones to a single photo library that works without the Cloud. Work anywhere and Mylio Photos+ keeps everything perfectly synced.",
      bHeaderEight: "SpaceSaver",
      bContentEight: "Optimize your photos to fit up to 15x more pictures on your mobile devices. When needed, download your full-quality files on demand from your home computer. This means you can store a lifetime of memories even on a smartphone.",
      bHeaderNine: "Photo DeDupe",
      bContentNine: "Scan your entire photo collection for duplicates. Clean up and merge unwanted files to recover precious hard drive space.",
      bHeaderTen: "Mylio Vault Protection",
      bContentTen: "Add a large hard drive and keep a backup copy of all your photos. Backup protection is fully automatic. Stop worrying about losing a device or catastrophic disk failures.",
    },
    howToContent: {
      header: "How to use Mylio Photos",
      addFilesHeader: "How to import your media",
      filterSmarttagHeader: "Find things faster with QuickFilters & SmartTags",
      editPhotosHeader: "Enhancing your photos",
      shareExportHeader: "Sharing your photos",
      backupHeader: "Protecting your photos and videos with Mylio Photos+",
    },
    faqContent:{
      faqHeader: "Frequently Asked Questions",
      faqQuestionOne: "What happens to my photos if I lose a device or my hard drive fails?",
      faqQuestionTwo: "Where does Mylio Photos store my files?",
      faqQuestionThree: "Why can’t I see my photos on the Mylio Photos website?",
      faqQuestionFour: "What happens to my files if I decide to cancel my Mylio Photos+ subscription?"
    },
    aboutPlus:{
      header: "About Mylio Plus",
      mpBanner: {
        header: "Upgrade to Mylio Photos Plus",
        subHeader: "Some subtext goes here"
      }
    },
    errorPage: {
      title: "Unable to connect",
      content: "Please connect to the internet to access the educational articles and interactive help.",
      bannerMsg: "Not connected to the internet"
    }
  }
}

export default strings
