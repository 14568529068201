import { Card, CardContent, Container, Grid, Stack, Typography, useMediaQuery } from '@mui/material'
import { useStyles } from '@styles'
import strings from '@json/strings'
import errorImg from '@assets/error-video-img.png'
import React from 'react';


const Error = () => {

 const isBig = useMediaQuery(`(min-width:1200px)`)
 const classes = useStyles()
 const [expanded, setExpanded] = React.useState(false);

 const handleExpandClick = () => {
   setExpanded(!expanded);
 };

 return (
  
  <>
  <Stack className={classes.gettingStartedView} sx={{ py: `6vh`, px: `40px`, overflow: `auto` }} justifyContent={isBig ? `left` : `top`}>
    <Container>
        <Grid container columnSpacing='8vw' rowSpacing={isBig ? 12 : 4 } sx={{ paddingBottom: 3, justifyContent: 'left', marginTop: '30px', paddingLeft: '0px' }}>
            <Stack
                justifyContent="left" 
                direction={{ xs: 'row', md: 'row' }} 
                spacing={{ xs: 1, sm: 2, md: 2, lg: 3 }} 
                useFlexGap 
                flexWrap="wrap"
                sx={{ padding: `50px 0 !important` }}>
                <Container>
                    <Card sx={{ maxWidth: `100%`, marginBottom:'30px' }}>
                    <Typography gutterBottom variant="h5" component="div" color='black' className={classes.startGuideHeader} sx={{ fontWeight: "700" }}>
                        {strings.views.errorPage.title}
                    </Typography>
                    <img
                        src={errorImg}
                        alt="error image"
                    />
                    <CardContent sx={{ padding: `0` }}>
                        <Typography variant="body2" color="black" className={classes.startGuideContent}>
                        {strings.views.errorPage.content}
                        </Typography>
                    </CardContent>
                    </Card>
                </Container>    
            </Stack>
 
        </Grid>
    </Container>
    <Card 
        sx={{ 
        backgroundColor: `red`, 
        paddingLeft: `30px`, 
        paddingTop: `10px !important`, 
        paddingBottom: `10px !important`, 
        borderRadius: `0`, 
        bottom: `40px`, 
        position: `absolute`, 
        width: `100%` }}>
        <CardContent sx={{ margin: `0 !important`, padding: `0 !important` }}>
            <Typography color="white" gutterBottom>
                {strings.views.errorPage.bannerMsg}                    
            </Typography>
        </CardContent>
    </Card>
 </Stack >
  </>
 )
}
export default Error