import { Box, Button, Card, CardContent, Container, Grid, IconButton, IconButtonProps, Paper, Stack, Typography, styled, useMediaQuery } from '@mui/material'
import { useStyles } from '@styles'
import strings from '@json/strings'
import mylioLogo from '@assets/Mylio Photos+.png'
import imgOne from '@assets/universal-photo-library-1.webp'
import imgTwo from '@assets/mylio_drive_image.png'
import imgThree from '@assets/AdobeStock_208003488-mock-1-1.webp'
import imgFour from '@assets/never-lose.webp'
import imgFive from '@assets/screenshot_mylio.png'
import imgSix from '@assets/drive_image.png'
import imgSeven from '@assets/universal-library_image.png'
import imgEight from '@assets/spacesaver_image.png'
import imgNine from '@assets/deDupe.png'
import imgTen from '@assets/vault.png'
import React, { useEffect } from 'react';

const Plans = () => {

 const isBig = useMediaQuery(`(min-width:1200px)`)
 const classes = useStyles()
 const [expanded, setExpanded] = React.useState(false);

 const handleExpandClick = () => {
   setExpanded(!expanded);
 };
 const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

useEffect(() => {
  document.body.classList.add('plans-layout')
  return () => {
    document.body.classList.remove('plans-layout')
  }
}, [])


 return (
  
  <>
  <Stack className={classes.gettingStartedView} 
    sx={{ py: `6vh`, overflow: `auto` }} 
    justifyContent={isBig ? `left` : `top`}>

    <Box
      sx={{
        paddingTop: '40px',
        paddingBottom: '20px',
        backgroundColor: '#000',
        paddingLeft: '25px',
        paddingRight: '25px'
      }}
    >
    <Container maxWidth="md">
     
      <Typography 
      color='white' 
      className={classes.pageHeader}
      sx={{ marginTop: `25px`, fontWeight: `bold` }}>
        
        <img
          src={mylioLogo}
          alt="placeholder image"
        />
        
      </Typography>
      <Container>
        <Typography variant="h5" align="center" color="text.secondary" paragraph sx={{ lineHeight: "1.7", fontSize: "26px" }} >
        Bring all your memories together - perfectly synced, fully protected, and ready to share with Mylio Photos+.
        
        Access all your files, on or offline, and find things fast.

        </Typography>
      </Container>
      
      <Stack
        sx={{ pt: 4 }}
        direction="row"
        spacing={2}
        justifyContent="center"
      >
        {/* <Button variant="outlined" className='mpPlusBtn' target='_blank' sx={{ color: "#fff", backgroundColor: "#36A68B", borderColor:"#36A68B" }} href="https://mylio.com/mylio-photos-plus">Learn More</Button> */}
      </Stack>
    </Container>
    
    </Box>
      <Container sx={{ backgroundColor: '#fff' }}>

      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 2 }} spacing={2}  sx={{ paddingBottom: '100px', justifyContent: 'left', marginTop: '30px', paddingLeft: '35px',  paddingRight:'20px' }}>
      
          <Container>
            <Typography 
              variant="h4" 
              align="left" 
              component="h4" 
              color='black' 
              fontWeight={700}
              className='plansHeader'>
              Everything in Mylio Photos and...
            </Typography>

            <Grid item xs={12} md={12} sx={{ marginTop: "50px" }}>
              <Card >
                <Typography gutterBottom variant="h5" component="div" color='black' className={classes.startGuideHeader} sx={{ fontWeight: "700" }}>
                  {strings.views.plansView.bHeaderOne}
                </Typography>
                <CardContent sx={{ padding: `0` }}>
                  <Typography variant="body2" color="black" className={classes.startGuideContent}>
                    {strings.views.plansView.bContentOne}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={12} sx={{ marginBottom: "60px" }}>
              <Container sx={{ paddingLeft: "0 !important" }}>
                <img
                  src={imgOne}
                  alt="placeholder image"
                  style={{ width: "100%", borderRadius: "10px" }}
                />
              </Container>
            </Grid>

            <Grid item xs={12} md={12} sx={{ marginTop: "10px" }}>
              <Card >
                <CardContent sx={{ padding: `0` }}>
                  <Typography variant="body2" color="black" className={classes.startGuideContent}>
                  Browse all your photos from any device and work independent of the Cloud.

                  Automatically backup your most important memories to multiple hard drives to protect against the loss or failure of a device.

                  Store your most important files on drives you own and can touch. All of the protection of the Cloud, with faster speeds, improved privacy, and predictable costs.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Container sx={{ clear: "both", marginBottom: "70px" }}></Container>

            <Grid item xs={12} md={6} sx={{ float: "left", marginBottom:'70px' }}>
              <Container sx={{ paddingLeft: "0 !important" }}>
                <img
                  src={imgTwo}
                  alt="placeholder image"
                  style={{ width: "100%", borderRadius: "10px" }}
                />
              </Container>
            </Grid>

            <Grid item xs={12} md={5} sx={{ float: "right", marginTop: "70px" }}>
              <Card sx={{ maxWidth: `100%` }}>

                <Typography gutterBottom variant="h5" component="div" color='black' className={classes.startGuideHeader} sx={{ fontWeight: "700" }}>
                  {strings.views.plansView.bHeaderTwo}
                </Typography>
                <CardContent sx={{ padding: `0` }}>
                  <Typography variant="body2" color="black" className={classes.startGuideContent}>
                    {strings.views.plansView.bContentTwo}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Container sx={{ clear: "both", marginBottom: "70px" }}></Container>

            <Grid item xs={12} md={5} sx={{ float: "left", marginTop: "70px" }}>
              <Card sx={{ maxWidth: `100%`, marginBottom:'15px' }}>

                <Typography gutterBottom variant="h5" component="div" color='black' className={classes.startGuideHeader} sx={{ fontWeight: "700" }}>
                  {strings.views.plansView.bHeaderThree}
                </Typography>
                <CardContent sx={{ padding: `0` }}>
                  <Typography variant="body2" color="black" className={classes.startGuideContent}>
                    {strings.views.plansView.bContentThree}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6} sx={{ float: "right", marginBottom:'70px' }}>
              <Container sx={{ paddingLeft: "0 !important" }}>
                <img
                  src={imgThree}
                  alt="placeholder image"
                  style={{ width: "100%", borderRadius: "10px" }}
                />
              </Container>
            </Grid>

            <Container sx={{ clear: "both", marginBottom: "70px" }}></Container>

            <Grid item xs={12} md={6} sx={{ float: "left", marginBottom:'70px' }}>
              <Container sx={{ paddingLeft: "0 !important" }}>
                <img
                  src={imgFour}
                  alt="placeholder image"
                  style={{ width: "100%", borderRadius: "10px" }}
                />
              </Container>
            </Grid>

            <Grid item xs={12} md={5} sx={{ float: "right", marginTop: "100px" }}>
              <Card sx={{ maxWidth: `100%`, marginBottom:'15px' }}>
                <Typography gutterBottom variant="h5" component="div" color='black' className={classes.startGuideHeader} sx={{ fontWeight: "700" }}>
                  {strings.views.plansView.bHeaderFour}
                </Typography>
                <CardContent sx={{ padding: `0` }}>
                  <Typography variant="body2" color="black" className={classes.startGuideContent}>
                    {strings.views.plansView.bContentFour}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Container sx={{ clear: "both", marginBottom: "70px" }}></Container>
            
            <Grid item xs={12} md={12} sx={{ marginBottom:'70px' }}>
              <Container>
                <img
                  src={imgFive}
                  alt="placeholder image"
                  style={{ width: "100%", borderRadius: "10px" }}
                />
              </Container>
            </Grid>

            <Grid item xs={12} md={12} sx={{ marginTop: "50px" }}>
              <Card sx={{ maxWidth: `100%`, marginBottom:'15px' }}>
                <Typography gutterBottom variant="h5" component="div" color='black' className={classes.startGuideHeader} sx={{ fontWeight: "700" }}>
                  {strings.views.plansView.bHeaderFive}
                </Typography>
                <CardContent sx={{ padding: `0` }}>
                  <Typography variant="body2" color="black" className={classes.startGuideContent}>
                    {strings.views.plansView.bContentFive}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Container sx={{ clear: "both", marginBottom: "70px" }}></Container>
            
            <Grid item xs={12} md={12} >
              <Container>
                <img
                  src={imgSix}
                  alt="placeholder image"
                  style={{ width: "100%", borderRadius: "10px" }}
                />
              </Container>
            </Grid>

            <Grid item xs={12} md={12} sx={{ marginTop:'30px' }}>
              <Card sx={{ maxWidth: `100%`, marginBottom:'15px' }}>
                <Typography gutterBottom variant="h5" component="div" color='black' className={classes.startGuideHeader} sx={{ fontWeight: "700" }}>
                  {strings.views.plansView.bHeaderSix}
                </Typography>
                <CardContent sx={{ padding: `0` }}>
                  <Typography variant="body2" color="black" className={classes.startGuideContent}>
                  Mylio Photos+ backup runs automatically on your computers. Mobile devices import and sync in the background or when the app runs

                  Plus, you can quickly check the status of all your files and devices in the <b>Sync Panel</b>.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>


            
            <Container sx={{ clear: "both", marginBottom: "70px" }}></Container>


            <Grid item xs={12} md={5} sx={{ float:"left", marginTop: "50px" }}>
              <Card sx={{ maxWidth: `100%`, marginBottom:'15px' }}>
                <Typography gutterBottom variant="h5" component="div" color='black' className={classes.startGuideHeader} sx={{ fontWeight: "700" }}>
                  {strings.views.plansView.bHeaderSeven}
                </Typography>
                <CardContent sx={{ padding: `0` }}>
                  <Typography variant="body2" color="black" className={classes.startGuideContent}>
                    {strings.views.plansView.bContentSeven}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6} sx={{ float: "right", marginBottom:'70px' }} className='noMargin' >
              <Container>
                <img
                  src={imgSeven}
                  alt="placeholder image"
                  style={{ width: "100%", borderRadius: "10px" }}
                />
              </Container>
            </Grid>

            <Container sx={{ clear: "both", marginBottom: "100px" }}></Container>

            <Grid item xs={12} md={5} sx={{ float: "right", marginTop: "50px" }}>
              <Card sx={{ maxWidth: `100%`, marginBottom:'15px' }}>
                <Typography gutterBottom variant="h5" component="div" color='black' className={classes.startGuideHeader} sx={{ fontWeight: "700" }}>
                  {strings.views.plansView.bHeaderEight}
                </Typography>
                <CardContent sx={{ padding: `0` }}>
                  <Typography variant="body2" color="black" className={classes.startGuideContent}>
                    {strings.views.plansView.bContentEight}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6} sx={{ float: "right" }} className='noMargin'>
              <Container>
                <img
                  src={imgEight}
                  alt="placeholder image"
                  style={{ width: "100%", borderRadius: "10px" }}
                />
              </Container>
            </Grid>

            <Container sx={{ clear: "both", marginBottom: "100px" }}></Container>

            <Grid item xs={12} md={5} sx={{ float: "left", marginTop: "100px" }}>
              <Card sx={{ maxWidth: `100%`, marginBottom:'15px' }}>
                <Typography gutterBottom variant="h5" component="div" color='black' className={classes.startGuideHeader} sx={{ fontWeight: "700" }}>
                  {strings.views.plansView.bHeaderNine}
                </Typography>
                <CardContent sx={{ padding: `0` }}>
                  <Typography variant="body2" color="black" className={classes.startGuideContent}>
                    {strings.views.plansView.bContentNine}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6} sx={{ float: "right", marginBottom:'70px' }}>
              <Container>
                <img
                  src={imgNine}
                  alt="placeholder image"
                  style={{ width: "100%", borderRadius: "10px" }}
                />
              </Container>
            </Grid>

            <Container sx={{ clear: "both", marginBottom: "50px" }}></Container>

            <Grid item xs={12} md={5} sx={{ float: "right", marginTop: "120px" }}>
              <Card sx={{ maxWidth: `100%`, marginBottom:'15px' }}>
                <Typography gutterBottom variant="h5" component="div" color='black' className={classes.startGuideHeader} sx={{ fontWeight: "700" }}>
                  {strings.views.plansView.bHeaderTen}
                </Typography>
                <CardContent sx={{ padding: `0` }}>
                  <Typography variant="body2" color="black" className={classes.startGuideContent}>
                    {strings.views.plansView.bContentTen}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6} sx={{ float: "right", marginBottom:'70px', marginRight: "80px" }} className='noMargin'>
              <Container>
                <img
                  src={imgTen}
                  alt="placeholder image"
                  style={{ width: "100%", borderRadius: "10px" }}
                />
              </Container>
            </Grid>


          </Container>
      
      </Grid>
    </Container>
    
  </Stack >
  </>
 )
}
export default Plans