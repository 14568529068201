import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { SnackbarProvider } from 'notistack'
import { store } from '@state/store'
import App from '@app'
import './index.scss'
// import * as serviceWorkerRegistration from './__serviceWorkerRegistration';

const root = createRoot(document.getElementById(`root`) as HTMLElement)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <SnackbarProvider maxSnack={5} autoHideDuration={5000} anchorOrigin={{ vertical: `top`, horizontal: `center` }}>
        <App />
      </SnackbarProvider>
    </Provider>
  </React.StrictMode> 
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

