import { Box, Button, Card, CardContent, Container, Divider, Grid, Paper, Stack, Typography, styled, useMediaQuery } from '@mui/material'
import { useStyles } from '@styles'
import mylioLogo from '@assets/Mylio Photos+.png'
import imgOne from '@assets/MylioV24-Update1.avif'
import imgTwo from '@assets/1729718453074.avif'
import imgThree from '@assets/1729718039212.avif'
import imgFour from '@assets/1729718108237.avif'
import imgFive from '@assets/1729716733620.avif'
import imgSix from '@assets/1729718548019.avif'
import imgSeven from '@assets/Speed.avif'
import imgEight from '@assets/1729718582491.avif'
import imgNine from '@assets/1729718651269.avif'
import imgTen from '@assets/1729718718006.avif'
import imgEleven from '@assets/1729718768281.avif'
import imgTwelve from '@assets/1729718811194.avif'
import imgThirteen from '@assets/Raw.avif'
import imgFourteen from '@assets/1729717509161.avif'
import imgFifteen from '@assets/1729717407535.avif'
import imgSixteen from '@assets/1729717407558.avif'
import bannerImage from '@assets/banner.png'
import { useEffect } from 'react';


const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const News = () => {

  const isBig = useMediaQuery(`(min-width:700px)`)
  const classes = useStyles()

  useEffect(() => {
    document.body.classList.add('news-layout')
    return () => {
      document.body.classList.remove('news-layout')
    }
  }, [])


  const profileCard = (
    <>
      <CardContent sx={{ backgroundColor: "#f5f5f5", width: isBig ? "300px" : "67vw", padding: "20px", borderRadius: "10px", justifyContent: "center", textAlign: "center" }}>
        <img
          src={imgOne}
          alt="placeholder image"
        />

        <Typography variant="h5" component="div" color="#000" fontWeight={'600'} sx={{ mb: 2, mt: 1.5 }}>Concierge</Typography>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 2 }} className='tag-container'>
          <Typography sx={{ mb: 1.5, backgroundColor:'#F9FAFD' }} className={classes.tags} fontSize={'13px'}>Small Bussinesses</Typography>
          <Typography sx={{ mb: 1.5, backgroundColor:'#F9FAFD' }} className={classes.tags} fontSize={'13px'}>Hobbyist</Typography>
        </Stack>

        <Typography variant="body2" color="#000">
          For those who don’t have time to do it themselves or seek mentorship on their journey.
        </Typography>
      </CardContent>
  
    </>
  );
  
  const serviceDescription = (
    <>
      <CardContent>
        {/* <Typography sx={{ fontSize: isBig? '34px' : '21px', fontWeight: 'bold' }} color="#000" gutterBottom>
          One-on-One Services
        </Typography> */}
        <Typography variant="h5" component="div" color="#000"  sx={{ fontSize: '16px', mt:3.5, mb:3 }}>
          Three one-hour sessions | $280 <span style={{textDecoration: 'line-through'}}>$450</span>
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="#000">
          We have a network of Photo Managers with Varying specialties such as:
        </Typography>
        <Stack direction='row' spacing={3} color="#000" className='tag-container' sx={{ display: 'inline' }}>
          <Typography className={classes.tags}>Cataloging scanned media</Typography>
          <Typography  className={classes.tags}>Mixed media libraries</Typography>
          <Typography  className={classes.tags}>Customized syncing</Typography>
          <Typography  className={classes.tags}>Shared accounts for work or family</Typography>
          <Typography  className={classes.tags}>Multi-layered backups</Typography>
          <Typography  className={classes.tags}>Small businesses</Typography>
        </Stack>
      </CardContent>
  
    </>
  );


 return (
  
  <>
  <Stack sx={{ height:'100vh', py: isBig ? `1vh` : `3vh`, paddingLeft: isBig? `0`:`92px`, paddingRight: isBig? `0`:`74px`, overflow: `auto`}} >


    <Stack direction="column" justifyContent={`center`} sx={{ px: isBig? `13vw` : `0vw`, margin: '0 auto' }}>
      <img
        src={imgOne}
        alt="placeholder image"
        width={'100%'}
        style={{ margin: '0 auto', marginTop:'20px', marginBottom: '20px' }}
      />
      <Typography sx={{ mb: 5, mt: 3.5, fontSize: isBig ? '48px !important' : '33px !important', fontWeight: 'bold' }} color="#000" variant='h3'>
        Introducing Mylio Photos v24.5 Update Five
      </Typography>

      <Stack direction={{ xs: 'column', sm: 'row' }} textAlign={'left'} sx={{ paddingBottom: '10px' }}>

        <Stack justifyContent={'center'} spacing={{sm: 1, md:4}} sx={{ paddingLeft: isBig? '0px' : '0px' }}>
          
          <Typography sx={{ mb: 3, mt: '0px !important'  }} color="#000" variant='body1'>
          We’re excited to announce a significant update to Mylio Photos, 24.5 (7787), which is now shipping. This update adds several new benefits and features.
            <br/><br/>
          <b>Update Version: 24.5 (7787)</b> <br/>
            <ul>
              <li>macOS Build: 24.5 (7787)</li>
              <li>Windows Build: 24.5 (7787)</li>
              <li>iOS Build: 24.5 (7787)</li>
              <li>Android Build: 24.5 (7787)</li>
            </ul>
        
          <i><b>Note:</b> It is a good idea to update all devices at the same time.</i>
          </Typography>
          {/* <Button variant="outlined" target='_blank' sx={{ color: "#0E9AF1", borderColor:"#0E9AF1", backgroundColor: "#fff", maxWidth: '200px', margin: '0 auto !important', marginTop: '30px !important' }} href="https://mylio.link/06020">Learn More</Button> */}
        </Stack>

      </Stack>

      {/* <Divider orientation="horizontal" variant="middle" flexItem sx={{ marginBottom: '60px', marginTop: '50px', borderColor: '#ddd', marginLeft: '0', marginRight: '0' }} /> */}
      
      <Stack direction={{ xs: 'column', sm: 'row' }} textAlign={'left'}>

        <Stack justifyContent={'center'} spacing={{sm: 1, md:4}} sx={{ paddingLeft: isBig? '0px' : '0px', paddingBottom: '10px' }}>
        <img
            src={imgTwo}
            alt="placeholder image"
            width={'100%'}
          />
          <Typography sx={{ fontWeight: '700', marginBottom: '20px'}} color="#000" variant='h5'>
          The short version
          </Typography>
          <Typography sx={{ mb: 3, mt: '0px !important'  }} color="#000" variant='body1'>
          Here’s what you can expect:
            <ul>
              <li>The Mylio Photos update is scheduled to ship this week.</li>
              <li>The update makes more than two hundred improvements to stability and performance.</li>
              <li>We've also added a significant update to Photo Sharing and Export</li>
              <li>This update is free to all users.</li>
              <li>Some of the features require a <a href='https://mylio.com/mylio-photos-plus'> Mylio Photos+ </a> membership or trial version.</li>
            </ul>
          
          </Typography>

          <img
            src={imgThree}
            alt="placeholder image"
            width={'100%'}
          />
          <Typography sx={{ fontWeight: '700', marginBottom: '20px', marginTop: '20px'}} color="#000" variant='h5'>
            Enhanced Sharing Panel in Mylio Photos
          </Typography>

          <Typography sx={{ mb: 2, mt: '0px !important'  }} color="#000" variant='body1'>
          We’ve dramatically improved the Sharing experience in Mylio Photos, offering a streamlined and consistent user interface across all platforms, including desktop and mobile. These updates apply to all export types, ensuring a unified experience.
          <br/><br/>
          <b>Key Updates:</b>
            <ul>
              <li><b>New Default Presets:</b> Optimized for 4K displays and high-quality printing.</li>
              <li><b>Custom Presets:</b> You can now create and save your custom export presets.</li>
              <li><b>Custom Watermarks & Metadata:</b> Include personalized watermarks and metadata within your presets.</li>
              <li><b>Preset Syncing:</b> Your custom presets will sync seamlessly between all your devices.</li>
              <li><b>Reorganized Share Menu:</b> We’ve restructured the menu for more straightforward navigation.</li>
              <li><b>Resolution Renamed to Dimensions:</b> We’ve renamed this option to reflect its function better.</li>
              <li><b>Under the Hood:</b> We’ve also fixed various bugs and enhanced the export engine's overall performance for a faster, more reliable experience.</li>
            </ul>

          </Typography>
          <Stack >
            <img
              src={imgFour}
              alt="placeholder image"
              width={'100%'}
            />
          </Stack>
          <Typography sx={{ mb: 3, mt: '10px !important'  }} color="#000" variant='body1'>
            <i><b>Pro Tip: </b> Click the settings icon next to the preset to edit an export template. To use a preset quickly in the future, simply click the main button.</i> 
          </Typography>  
          {/* <Button variant="outlined" target='_blank' sx={{ color: "#0E9AF1", borderColor:"#0E9AF1", backgroundColor: "#fff", maxWidth: '200px', margin: '0 auto !important', marginTop: '30px !important'  }} href="https://mylio.link/06021">Learn More</Button> */}
        </Stack>

        

      </Stack>

      <Divider orientation="horizontal" variant="middle" flexItem sx={{ marginBottom: '60px', marginTop: '50px', borderColor: '#ddd', marginLeft: '0', marginRight: '0' }} />


      <Stack direction={{ xs: 'column', sm: 'row' }} textAlign={'left'}>

        <Stack justifyContent={'center'} spacing={{sm: 1, md:4}} sx={{ paddingLeft: isBig? '0px' : '0px'}}>
        <Stack >
          <img
            src={imgFive}
            alt="placeholder image"
            width={'100%'}
          />
        </Stack>
          <Typography sx={{ fontWeight: '700', marginBottom: '20px', marginTop: '20px'}} color="#000" variant='h5'>
          Mylio Drive+ and SecureCloud Enhancements
          </Typography>
          <Typography sx={{ mb: 2, mt: '0px !important'  }} color="#000" variant='body1'>
            We’ve made significant updates to both Mylio Drive+ and your SecureCloud plan, bringing improved performance, easier setup, and more control over your storage and syncing options.
            <br/>
            Key Updates:
            <ul>
              <li><b>Improved Performance:</b> Faster syncing and smoother operation across all devices.</li>
              <li><b>Simplified Setup:</b> Getting started with Mylio Drive+ and a SecureCloud Plan is now more straightforward than ever.</li>
              <li><b>Enhanced Full Storage Notifications:</b> Receive clearer notifications when your storage is nearing capacity so you can take action before it's full.</li>
              <li><b>Cache Clearing for Files:</b> You can now manually clear cached files to reduce the amount of online storage being used and free up space for essential files.</li>
              <li><b>SecureCloud Plan Protection:</b> For added security, you can no longer cancel or delete your SecureCloud Plan until all Originals are safely synced to a local vault device.</li>
              <li><b>SyncCollections with Mylio Drive+:</b> Gain greater control over how your Originals and Optimized files sync by using SyncCollections, allowing you to fine-tune your storage management.</li>
            </ul>
          </Typography>
          {/* <Button variant="outlined" target='_blank' sx={{ color: "#0E9AF1", borderColor:"#0E9AF1", backgroundColor: "#fff", maxWidth: '200px', margin: '0 auto !important', marginTop: '30px !important'  }} href="https://mylio.link/06022">Learn More</Button> */}
        </Stack>

        

      </Stack>


      <Divider orientation="horizontal" variant="middle" flexItem sx={{ marginBottom: '60px', marginTop: '50px', borderColor: '#ddd', marginLeft: '0', marginRight: '0' }} />

      <Stack direction={{ xs: 'column', sm: 'row' }} textAlign={'left'}>

      
        <Stack justifyContent={'center'} spacing={{sm: 1, md:4}} sx={{ paddingLeft: isBig? '0px' : '0px', paddingBottom: '10px' }}>
          <img
            src={imgSix}
            alt="placeholder image"
            width={'100%'}
          />
          {/* <Typography sx={{ fontWeight: '700', marginBottom: '20px'}} color="#000" variant='h5'>
            QuickFilter Panel Improvements
          </Typography> */}
          <Typography sx={{ mb: 3, mt: '20px !important'  }} color="#000" variant='body1'>
          These updates ensure more reliable and secure file handling, giving you more peace of mind while using Mylio Drive+ and your SecureCloud Plan. If you aren’t using it yet, just click the Devices panel and click the Add+ button to get started.
          
          </Typography>
          {/* <Button variant="outlined" target='_blank' sx={{ color: "#0E9AF1", borderColor:"#0E9AF1", backgroundColor: "#fff", maxWidth: '200px', margin: '0 auto !important', marginTop: '30px !important'  }} href="https://mylio.link/06023">Learn More</Button> */}
        </Stack>

      </Stack>

      <Divider orientation="horizontal" variant="middle" flexItem sx={{ marginBottom: '60px', marginTop: '50px', borderColor: '#ddd', marginLeft: '0', marginRight: '0' }} />


      <Stack direction={{ xs: 'column', sm: 'row' }} textAlign={'left'}>

        <Stack justifyContent={'center'} spacing={{sm: 1, md:4}} sx={{ paddingLeft: isBig? '0px' : '0px', paddingBottom: isBig ? '20px' : '20px'  }}>
          <img
            src={imgSeven}
            alt="placeholder image"
            width={'100%'}
          />
          <Typography sx={{ fontWeight: '700', marginBottom: '20px'}} color="#000" variant='h5'>
            Performance and Usability Updates
            <br/><br/>
          </Typography>
          <Typography sx={{ mb: 3, mt: '0px !important'  }} color="#000" variant='body1'>
          This release focuses on improving overall performance and fixing various stability issues. Over 200 community-reported requests were addressed, and numerous performance enhancements were made, including resolving several crash issues. 
            <br/><br/>
            Here are the notable usability and performance improvements:
            <br/><br/>
            <Typography sx={{ fontWeight: '400', marginBottom: '10px'}} color="#000" variant='h5'>
              Albums
            </Typography>
           <ul>
            <li>Privacy and storage settings now correctly apply when adding photos to an album.</li>
           </ul>
           <Typography sx={{ fontWeight: '400', marginBottom: '10px'}} color="#000" variant='h5'>
           Apple Photos
           </Typography>
           <ul>
            <li>Fixed an issue where some photos were incorrectly paired when Apple reused filenames (common during device upgrades). </li>
            <li>A library scan and repair step has been added to fix these issues.</li>
           </ul>
           <Typography sx={{ fontWeight: '400', marginBottom: '10px'}} color="#000" variant='h5'>
           Calendar View
            </Typography>
           <ul>
            <li>Improved how events are displayed when searching in Calendar view.</li>
            <li>You can now easily edit cover photos for events directly from the Info Panel.</li>
           </ul>


          </Typography>
          {/* <Button variant="outlined" target='_blank' sx={{ color: "#0E9AF1", borderColor:"#0E9AF1", backgroundColor: "#fff", maxWidth: '200px', margin: '0 auto !important', marginTop: '30px !important'  }} href="https://mylio.link/06024">Learn More</Button> */}
        </Stack>

      </Stack>

      <Divider orientation="horizontal" variant="middle" flexItem sx={{ marginBottom: '60px', marginTop: '50px', borderColor: '#ddd', marginLeft: '0', marginRight: '0' }} />

      <Stack direction={{ xs: 'column', sm: 'row' }} textAlign={'left'}>
        <Stack justifyContent={'center'} spacing={{sm: 1, md:4}} sx={{ paddingLeft: isBig? '0px' : '0px', paddingBottom: '10px' }}>
          <img
            src={imgEight}
            alt="placeholder image"
            width={'100%'}
          />
          <Typography sx={{ mb: 3, mt: '0px !important'  }} color="#000" variant='body1'>
          <Typography sx={{ fontWeight: '400', marginBottom: '10px', mt:'20px'}} color="#000" variant='h5'>
            Categories
          </Typography>
           <ul>
            <li>Fixed a bug where categories were incorrectly applied to imported media.</li>
            <li>You can now set categories when importing from the Files app on iOS.</li>
           </ul>
           <Typography sx={{ fontWeight: '400', marginBottom: '10px'}} color="#000" variant='h5'>
            Documents
           </Typography>
           <ul>
            <li>Preview icons for Office documents on macOS are now working correctly.</li>
           </ul>
           <Typography sx={{ fontWeight: '400', marginBottom: '10px'}} color="#000" variant='h5'>
            Dynamic Search
          </Typography>
           <ul>
            <li>You can now drag and drop media from Finder or Explorer into a visible folder to copy it.</li>
            <li>Multi-word keyword search terms are now supported.</li>
           </ul>
           <Typography sx={{ fontWeight: '400', marginBottom: '10px'}} color="#000" variant='h5'>
           Editing
          </Typography>
           <ul>
            <li>Mobile editing presets now include a reset option for easier adjustments.</li>
            <li>Improved how the Levels command is tracked for Undo and preset purposes, making it easier to manage edits.</li>
           </ul>
           </Typography>
          {/* <Button variant="outlined" target='_blank' sx={{ color: "#0E9AF1", borderColor:"#0E9AF1", backgroundColor: "#fff", maxWidth: '200px', margin: '0 auto !important', marginTop: '30px !important'  }} href="https://mylio.link/06025">Learn More</Button> */}
        </Stack>

        


      </Stack>
      <Divider orientation="horizontal" variant="middle" flexItem sx={{ marginBottom: '60px', marginTop: '50px', borderColor: '#ddd', marginLeft: '0', marginRight: '0' }} />

      <Stack direction={{ xs: 'column', sm: 'row' }} textAlign={'left'}>

        <Stack justifyContent={'center'} spacing={{sm: 1, md:4}} sx={{ paddingLeft: isBig? '0px' : '0px', paddingBottom: '10px' }}>
          <img
            src={imgNine}
            alt="placeholder image"
            width={'100%'}
          />

          <Typography sx={{ mb: 3, mt: '0px !important'  }} color="#000" variant='body1'>
          <br/><br/>
            <Typography sx={{ fontWeight: '400', marginBottom: '10px'}} color="#000" variant='h5'>
            Face Tagging
            </Typography>
           <ul>
            <li>The Rescan Faces command now works faster for improved performance.</li>
            <li>Added support for names with commas (e.g., "lastname, Sr.") in name fields.</li>
            <li>Confirming faces now removes them from the proposed list.</li>
            <li>Ignored faces are still counted when using the FaceCount custom filter.</li>
            <li>A new "Zoom to Face" switch has been added to the People view for easier face tagging.</li>
            <li>Exiting Batch Face Tagging mode automatically occurs when switching views.</li>
           </ul>
           <Typography sx={{ fontWeight: '400', marginBottom: '10px'}} color="#000" variant='h5'>
           frame.io
           </Typography>
           <ul>
            <li>Improved support for reading existing XMP sidecar files during import.</li>
            <li>RAW and JPEG pairs are now bundled together during import.</li>
            <li>You can now choose whether to skip importing duplicate files when using frame.io importers.</li>
            <li>Increased stability when posting new versions to frame.io.</li>
           </ul>
           <Typography sx={{ fontWeight: '400', marginBottom: '10px'}} color="#000" variant='h5'>
            Geotagging
            </Typography>
           <ul>
            <li>The "Location Shown" field now displays more text for easier reading.</li>
            <li>Long-press (or right-click) on the map to add selected photos, even in full-screen view.</li>
            <li>The map now pans dynamically based on selected geotagged photos in grid view.</li>
           </ul>
           <Typography sx={{ fontWeight: '400', marginBottom: '10px'}} color="#000" variant='h5'>
           Importing
            </Typography>
           <ul>
            <li>Added a filter dialog and recent folders to the folder tree to select a destination during import.</li>
            <li>Mylio Scanner now detects new images and external changes more accurately.</li>
            <li>Improved support for importing from the Apple Files app on iOS.</li>
           </ul>

           </Typography>
          {/* <Button variant="outlined" target='_blank' sx={{ color: "#0E9AF1", borderColor:"#0E9AF1", backgroundColor: "#fff", maxWidth: '200px', margin: '0 auto !important', marginTop: '30px !important'  }} href="https://mylio.link/06026">Learn More</Button> */}
        </Stack>

      </Stack>
      <Divider orientation="horizontal" variant="middle" flexItem sx={{ marginBottom: '60px', marginTop: '50px', borderColor: '#ddd', marginLeft: '0', marginRight: '0' }} />
      <Stack direction={{ xs: 'column' }} textAlign={'left'} sx={{ paddingBottom: '10px'}}>

          <img
            src={imgTen}
            alt="placeholder image"
            width={'100%'}
          />
       
        <Stack justifyContent={'center'} spacing={{sm: 1, md:4}} sx={{ paddingLeft: isBig? '0px' : '0px' }}>
        <Typography sx={{ mb: 3, mt: '0px !important'  }} color="#000" variant='body1'>
          <br/><br/>
          <Typography sx={{ fontWeight: '400', marginBottom: '10px'}} color="#000" variant='h5'>
            Mylio Clipboard
          </Typography>
           <ul>
            <li>Pressing the B key now adds selected media, albums, or folders to the Mylio Clipboard panel and opens it if it’s closed.</li>
            <li>Double-clicking an item in the clipboard takes you directly to its location.</li>
           </ul>
           <Typography sx={{ fontWeight: '400', marginBottom: '10px'}} color="#000" variant='h5'>
            Photo DeDupe
           </Typography>
           <ul>
            <li>You can now use Unflagged, Picked, or Rejected flags as criteria when running Photo DeDupe.</li>
           </ul>
           <Typography sx={{ fontWeight: '400', marginBottom: '10px'}} color="#000" variant='h5'>
            QuickFilters
          </Typography>
           <ul>
           <li>Now supports multi-word keyword QuickFilters for more precise searching.</li>
           <li>Multiple keyword filters are treated as an AND combination, refining your results.</li>
           <li>Solo Mode allows only one group to be open at a time for better focus.</li>
           <li>A refresh button has been added to the QuickFilters settings (gear icon) to update the list for recent media changes.</li>
           <li>Fixed issues when filtering by "Unknown Camera" or "Unknown Make" lens.</li>
           <li>Improved filtering for multiple file types or extensions.</li>
           </ul>
        
          </Typography>
          {/* <Button variant="outlined" target='_blank' sx={{ color: "#0E9AF1", borderColor:"#0E9AF1", backgroundColor: "#fff", maxWidth: '200px', margin: '0 auto !important', marginTop: '30px !important'  }} href="https://mylio.link/06027">Learn More</Button> */}
        </Stack>

      </Stack>
      <Divider orientation="horizontal" variant="middle" flexItem sx={{ marginBottom: '60px', marginTop: '50px', borderColor: '#ddd', marginLeft: '0', marginRight: '0'}} />

      <Stack direction={{ xs: 'column', sm: 'row' }} textAlign={'left'}>

        <Stack justifyContent={'center'} spacing={{sm: 1, md:4}} sx={{ paddingLeft: isBig? '0px' : '0px', paddingBottom: '10px', paddingTop: isBig ? '0px' : '40px'  }}>
          <img
            src={imgEleven}
            alt="placeholder image"
            width={'100%'}
          />
          <br/><br/>
          <Typography sx={{ mb: 3, mt: '0px !important'  }} color="#000" variant='body1'>
          <Typography sx={{ fontWeight: '400', marginBottom: '10px'}} color="#000" variant='h5'>
            QuickReview and Single Image Gestures
          </Typography>
           <ul>
            <li>Star rating gestures now work even when the left panel is open, allowing for faster photo rating. To launch, click the More menu (...) and choose QuickReview.</li>
           </ul>
           <Typography sx={{ fontWeight: '400', marginBottom: '10px'}} color="#000" variant='h5'>
            Sharing
           </Typography>
           <ul>
            <li>Mylio Photos now remembers your display preferences for folder views in the top-level share panel.</li>
            <li>Added the option to include filenames when sharing via Shared Album or Shareable Link.</li>
           </ul>
           <Typography sx={{ fontWeight: '400', marginBottom: '10px'}} color="#000" variant='h5'>
            Slideshows
          </Typography>
           <ul>
           <li>Fixed an issue where slideshows wouldn’t work for images inside the Apple Photos folder.</li>
           </ul>
        
          </Typography>


          {/* <Button variant="outlined" target='_blank' sx={{ color: "#0E9AF1", borderColor:"#0E9AF1", backgroundColor: "#fff", maxWidth: '200px', margin: '0 auto !important', marginTop: '30px !important'  }} href="https://mylio.link/06028">Learn More</Button> */}
          {/* <Button variant="outlined" target='_blank' sx={{ color: "#0E9AF1", borderColor:"#0E9AF1", backgroundColor: "#fff", maxWidth: '200px', marginTop: '30px !important', margin: '0 auto !important'  }} href="https://manual.mylio.com/topic/post-to-viewbug">Learn More</Button> */}
        </Stack>

      </Stack>

      <Divider orientation="horizontal" variant="middle" flexItem sx={{ marginBottom: '40px', marginTop: '50px', borderColor: '#ddd', marginLeft: '0', marginRight: '0' }} />

      <Stack direction={{ xs: 'column', sm: 'row' }} textAlign={'left'}>

        <Stack justifyContent={'center'} spacing={{sm: 1, md:4}} sx={{ paddingLeft: isBig? '0px' : '0px', paddingTop: '30px' }}>
          <img
            src={imgTwelve}
            alt="placeholder image"
            width={'100%'}
          />
        <Typography sx={{ mb: 3, mt: '0px !important'  }} color="#000" variant='body1'>
          <br/><br/>
          <Typography sx={{ fontWeight: '400', marginBottom: '10px'}} color="#000" variant='h5'>
            Temporary Sync Conflicts Folder
          </Typography>
           <ul>
            <li>A temporary sync folder will appear when major sync conflicts arise between two devices.</li>
            <li>Clear instructions are provided in the Info Panel to help resolve the issue.</li>
            <li>Once the conflict is resolved, the folder is automatically hidden.</li>
           </ul>
           <Typography sx={{ fontWeight: '400', marginBottom: '10px'}} color="#000" variant='h5'>
            User Interface
           </Typography>
           <ul>
            <li>Tab navigation works across more fields in the UI and Info Panel for smoother navigation.</li>
            <li>Shift+Tab now allows you to move backward through fields.</li>
            <li>Umlauts are now better supported in search and filters for more accurate results.</li>
            <li>Buttons in the Info Panel have improved consistency for a more polished look.</li>
            <li>Preference controls for Face Recognition and SmartTags have been improved for easier management.</li>
            <li>The Mylio Clipboard is hidden from Dashboard View for a cleaner interface.</li>
            <li>Linked folder counts in the Sources area of the Device Panel are now correctly calculated.</li>
            <li>Tapping the top bar in Single Image View toggles information about the file, folder, or album.</li>
            <li>Improved scrolling in the Info Panel allows headers to be dragged.</li>
            <li>The scroll wheel direction for advancing through the filmstrip now matches the full-screen view.</li>
           </ul>
           <Typography sx={{ fontWeight: '400', marginBottom: '10px'}} color="#000" variant='h5'>
           Watermarks
          </Typography>
           <ul>
            <li>Improved image watermark sizing for more accurate placement.</li>
            <li>You can now save watermarks with export presets for quick reuse.</li>
            <li>Watermarks are now unique to each export type, preventing accidental use of the wrong watermark.</li>
           </ul>
        
          </Typography>
          {/* <Button variant="outlined" target='_blank' sx={{ color: "#0E9AF1", borderColor:"#0E9AF1", backgroundColor: "#fff", maxWidth: '200px', margin: '0 auto !important', marginTop: '30px !important'  }} href="https://mylio.link/06029">Learn More</Button> */}
        </Stack>

      </Stack>
      <Divider orientation="horizontal" variant="middle" flexItem sx={{ marginBottom: '20px', marginTop: '50px', borderColor: '#ddd', marginLeft: '0', marginRight: '0' }} />
      <Stack direction={{ xs: 'column', sm: 'row' }} textAlign={'left'}>

        <Stack justifyContent={'center'} spacing={{sm: 1, md:4}} sx={{ paddingLeft: isBig? '0px' : '0px', paddingBottom: isBig ? '10px' : '20px', paddingTop: isBig ? '50px' : '50px' }}>
          <img
            src={imgThirteen}
            alt="placeholder image"
            width={'100%'}
          />
          <Typography sx={{ fontWeight: '700', marginBottom: '10px', paddingTop: '0px' }} color="#000" variant='h5'>
          New Camera Support
          </Typography>
          <Typography color="#000">
          We've added additional camera model support.
          <ul>
              <li>Panasonic Lumix DC-GH6</li>
              <li>Panasonic DC-S5M2</li>
              <li>Panasonic DC-G9 II</li>
              <li>Panasonic DC-S9</li>
              <li>Canon EOS R1</li>
              <li>Canon EOS R5 Mark II</li>
              <li>Cropped NEF files from Nikon Z7 II are now supported</li>
            </ul>
            {/* <a target="_blank" href='https://support.mylio.com/en/articles/8149668-change-log-and-release-notes' color='#000'><b>See the Full Release Notes</b></a> */}
          </Typography>
            
        </Stack>

      </Stack>

      <Divider orientation="horizontal" variant="middle" flexItem sx={{ marginBottom: '20px', marginTop: '50px', borderColor: '#ddd', marginLeft: '0', marginRight: '0' }} />
      
      <Stack direction={{ xs: 'column', sm: 'row' }} textAlign={'left'}>

        <Stack justifyContent={'center'} spacing={{sm: 1, md:4}} sx={{ paddingLeft: isBig? '0px' : '0px', paddingBottom: isBig ? '10px' : '20px', paddingTop: isBig ? '50px' : '50px' }}>
          <img
            src={imgFourteen}
            alt="placeholder image"
            width={'100%'}
          />
          <Typography sx={{ fontWeight: '700', marginBottom: '10px', paddingTop: '0px' }} color="#000" variant='h5'>
            How to update the app?
          </Typography>
          <Typography color="#000">
          When connected to the internet, launch Mylio Photos. The desktop application should prompt you for an update. Most mobile devices are set to auto-update, so check the Help menu and choose About Mylio Photos” to verify the device's version. If you still need the mobile update, visit the iOS App Store or Google Play Store.
<br/><br/>
If Mylio Photos crashes when opening, you can manually download the update here – <a href="https://mylio.com/download">https://mylio.com/download</a>.
            {/* <a target="_blank" href='https://support.mylio.com/en/articles/8149668-change-log-and-release-notes' color='#000'><b>See the Full Release Notes</b></a> */}
          </Typography>
            
        </Stack>

      </Stack>

      <Divider orientation="horizontal" variant="middle" flexItem sx={{ marginBottom: '20px', marginTop: '50px', borderColor: '#ddd', marginLeft: '0', marginRight: '0' }} />
      
      <Stack direction={{ xs: 'column', sm: 'row' }} textAlign={'left'}>

        <Stack justifyContent={'center'} spacing={{sm: 1, md:4}} sx={{ paddingLeft: isBig? '0px' : '0px', paddingBottom: isBig ? '10px' : '20px', paddingTop: isBig ? '50px' : '50px' }}>
          <img
            src={imgFifteen}
            alt="placeholder image"
            width={'100%'}
          />
          <Typography sx={{ fontWeight: '700', marginBottom: '0px', paddingTop: '0px' }} color="#000" variant='h5'>
            Reminder of minimum system requirements
          </Typography>
          <Typography color="#000">
          <ul>
              <li><b>Windows Computers and Tablets:</b> Windows 10 version 21H2 (64-bit) or later; Windows 11</li>
              <li><b>Windows ARM – Surface X Pro:</b> Windows 10 version 21H2 (64-bit) or later; Windows 11</li>
              <li><b>Android Phones and Tablets:</b> Android 11 or later</li>
              <li><b>Macintosh Computers:</b> macOS 11 or later</li>
              <li><b>iPhone and iPad Devices:</b> iOS 16 or later</li>
            </ul>
            While older devices can still run previous versions of Mylio Photos, this will change in upcoming updates. Our next major update, expected this winter, will require a database upgrade, which older devices will not support. As a result, these devices will no longer sync.
<br/><br/>
Please ensure your device is upgraded to a supported OS to avoid disruption. If your device can’t be updated due to manufacturer limitations, consider replacing it with a newer or refurbished model that meets the minimum requirements.
            {/* <a target="_blank" href='https://support.mylio.com/en/articles/8149668-change-log-and-release-notes' color='#000'><b>See the Full Release Notes</b></a> */}
          </Typography>
            
        </Stack>

      </Stack>


      <Divider orientation="horizontal" variant="middle" flexItem sx={{ marginBottom: '20px', marginTop: '50px', borderColor: '#ddd', marginLeft: '0', marginRight: '0' }} />
      
      <Stack direction={{ xs: 'column', sm: 'row' }} textAlign={'left'}>

        <Stack justifyContent={'center'} spacing={{sm: 1, md:4}} sx={{ paddingLeft: isBig? '0px' : '0px', paddingBottom: isBig ? '10px' : '20px', paddingTop: isBig ? '50px' : '50px' }}>
          <img
            src={imgSixteen}
            alt="placeholder image"
            width={'100%'}
          />
          <Typography sx={{ fontWeight: '700', marginBottom: '0px', paddingTop: '0px' }} color="#000" variant='h5'>
            What’s next?
          </Typography>
          <Typography color="#000">
          We have started a major new development sprint that will run for an extended time. During this time, we will address several areas of the application and account management experience. We will share more details throughout the Winter as we expand Mylio Photos' capabilities.
          <br/><br/>
          Potential items include
          <ul>
            <li>Expansion of Family Plans for Mylio Photos</li>
            <li>Expansion of Small Business plans for Mylio Photos</li>
            <li>Additional Navigation options</li>
            <li>Additional security features</li>
          </ul>
          <br/><br/>
            {/* <a target="_blank" href='https://support.mylio.com/en/articles/8149668-change-log-and-release-notes' color='#000'><b>See the Full Release Notes</b></a> */}
          </Typography>
            
        </Stack>

      </Stack>


      {/* <Divider orientation="horizontal" variant="middle" flexItem sx={{ marginBottom: '60px', marginTop: '50px', borderColor: '#ddd', marginLeft: '0', marginRight: '0' }} />*/}

      
    </Stack>
    

      
      {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 2, lg:4 }} sx={{ px: isBig? `13vw` : `0vw` }}>
        <Typography sx={{ fontWeight: '700', marginBottom: '30px' }} color="#000" variant='h4'>
          One-on-One Services
        </Typography>
        <Stack>
          {profileCard}
        </Stack>
        <Stack sx={{ paddingLeft: isBig? `60px` : `0`  }}>
          {serviceDescription}
        </Stack>
      </Stack> */}

      {/* <Stack direction="column" justifyContent={`center`} sx={{ px: isBig? `13vw` : `0vw`, margin: '0 auto', paddingBottom: isBig ? '60px' : '0px'  }}>

        <Typography sx={{ mb: 1.5, mt: 10, fontSize: '40px', fontWeight: 'bold' }} color="#000" variant='h3'>
          What can they help you with?
        </Typography>
        
        <Typography sx={{ mb: 5, fontSize: '24px', fontWeight: '700' }} color="#000" variant='h1'>
          Some of the things our Photo Managers specialize in
        </Typography>

        <Stack direction={{ xs: 'column', sm: 'row' }} textAlign={'left'}>

          <Stack justifyContent={'center'} spacing={{sm: 1, md:4}} sx={{ paddingLeft: isBig? '0px' : '0px' }}>
            <Typography sx={{ fontWeight: '700' }} color="#000" variant='body1'>
              Catalog scanned media
            </Typography>
            <Typography sx={{ mb: 3, mt: '0px !important'  }} color="#000" variant='body1'>
            Catalog scanned media for easy browsing and searching, integrated with your digital media.
            </Typography>

            <Typography sx={{ fontWeight: '700' }} color="#000" variant='body1'>
              Customized syncing
            </Typography>
            <Typography sx={{ mb: 3, mt: '0px !important'  }} color="#000" variant='body1'>
            Configure syncing for each device, ensuring you only have the desired content on each one.
            </Typography>


            <Typography  sx={{  fontWeight: '700',  }} color="#000" variant='body1'>
              Multi-layered backups
            </Typography>
            <Typography sx={{ mb: 3, mt: '0px !important'  }} color="#000" variant='body1'> 
              Establish a multi-layered backup process that includes hard drives, NAS, computers, tablets, smartphones, and cloud accounts.
            </Typography>
          </Stack>

          <Stack sx={{ paddingTop: '60px', paddingLeft: isBig ? '60px' : '12px', paddingRight: isBig ? '60px' : '0px' }}>
            <img
              src={imgTwo}
              alt="placeholder image"
              width={'100%'}
            />
          </Stack>

        </Stack>

        

   
     

      </Stack>  */}

{/* 
      <Stack direction={{ xs: 'column', sm: 'column' }} justifyContent={`center`} sx={{ px: isBig? `13vw` : `0vw` , margin: '0 auto' }}>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing="2" textAlign={'left'} >

          <Stack sx={{ paddingTop: '60px', paddingRight: isBig ? '80px': '0' }}>
            <img
              src={imgThree}
              alt="placeholder image"
              width={'100%'}
            />
          </Stack>

          <Stack justifyContent={'center'}>
            <Typography sx={{ fontWeight: '700' }} color="#000" variant='body1'>
              Mixed-media libraries
            </Typography>
            <Typography sx={{ mb: 3 }} color="#000" variant='body1'>
            Create a seamless system for cataloging and searching across various file types.
            </Typography>

            <Typography sx={{ fontWeight: '700' }} color="#000" variant='body1'>
              Shared accounts
            </Typography>
            <Typography sx={{ mb: 3  }} color="#000" variant='body1'>
            Setup multiple accounts for collaboration with family, friends, or colleagues. Control over what you share.
            </Typography>

            <Typography sx={{ fontWeight: '700' }} color="#000" variant='body1'>
              Small businesses
            </Typography>
            <Typography sx={{ mb: 3 }} color="#000" variant='body1'>
            Businesses that handle a significant amount of media. Typically for creative, marketing, collaboration, or archival purposes.
            </Typography>
          </Stack>

      </Stack> */}

    {/* </Stack> 

      <Stack direction="column" justifyContent={`center`} sx={{  margin: '0 auto', backgroundColor: '#FBFCFE', mb:6, mt:6 }}>
        <Typography sx={{ mb: 1.5, mt:5, fontSize: '34px', fontWeight: '700', textAlign: 'left', px: isBig? `13vw` : `0vw` }} color="#000" variant='h1' >
          What should you expect?
        </Typography>
        
          <Stack sx={{ paddingLeft:isBig? '65px' : '0', py: isBig? '50px' : '0'}}>
            <img
              src={timelineImage}
              alt="placeholder image"
              width={'100%'}
            />
          </Stack>
        

      </Stack>



      <Typography sx={{ mb: 3, mt:5, fontSize: '34px', fontWeight: '700', textAlign: 'left', pl: isBig? `13vw` : `0vw` }} color="#000" variant='h1'>
        Want to learn more before commiting?
      </Typography>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{xs:1, sm:1, lg:2}} textAlign={"left"} sx={{ px: isBig? `10vw` : `0`}}>
        
        <Stack sx={{ padding: isBig? '50px': '10px' }}>
          <Typography sx={{ mb: 1.5, fontWeight: '700' }} color="#000" variant='h5'>
            Chat with us          
          </Typography>
          <Typography variant='body1' color="#000" sx={{ mb:3, mt:.5 }} >
            Connect with a live member of our team through the chat bubble at the bottom of your browser window. 
          </Typography>
          <Button variant="outlined" target='_blank' sx={{ color: "#0E9AF1", borderColor:"#0E9AF1", backgroundColor: "#fff", maxWidth: '200px' }} href="#">Chat Now</Button>
        </Stack>

        <Stack sx={{ padding: isBig? '50px': '10px' }}>
          <Typography sx={{ mb: 1.5, fontWeight: '700' }} color="#000" variant='h5'>
            Use Mylio Photos today         
          </Typography>
          <Typography variant='body1' color="#000" sx={{ mb:3, mt:.5 }}>
            All yearly subscriptions come with a free 30 minute set up session. See how that works for you before trying out more. 
          </Typography>
          <Button variant="outlined" target='_blank' sx={{ color: "#0E9AF1", borderColor:"#0E9AF1", backgroundColor: "#fff", maxWidth: '200px' }} href="#">Subscribe</Button> 
        </Stack>
      </Stack> */}

    

  </Stack>
  </>
 )
}
export default News