import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { VariantType } from "notistack"
import { getURLParam } from "@helpers"


export type NotificationType = {
  message: string
  variant?: VariantType
}


interface AppState {
  darkMode?: boolean // Unused currently
  embedded?: boolean
  notification?: NotificationType
}

export const initialState: AppState = {
  embedded: getURLParam(`embedded`) === `true`,
}

const appSlice = createSlice({
  name: `app`,
  initialState,
  reducers: {
    notification: (state, action: PayloadAction<NotificationType>) => { state.notification = action.payload },
    clearNotification: state => { state.notification = undefined },
    appReset: () => initialState
  },
})

export const {
  notification,
  clearNotification
} = appSlice.actions
export default appSlice.reducer
