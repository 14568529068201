import { useStyles } from '@/styles/styles'
import { Container, Grid, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography, useMediaQuery } from '@mui/material'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { useEffect } from 'react';

const LearnMore = () => {
    const isBig = useMediaQuery(`(min-width:1200px)`)
    const classes = useStyles()

    useEffect(() => {
      document.body.classList.add('more-layout')
      return () => {
        document.body.classList.remove('more-layout')
      }
    }, [])
    
    return (
        <>
  <Stack sx={{ overflow: `auto`, marginLeft: `60px` }} justifyContent={isBig ? `left` : `top`}>

    <Stack sx={{ backgroundColor: '#fff', paddingLeft: '20px' }}>

      <Grid container rowSpacing={2}   sx={{ paddingBottom: '26px', justifyContent: 'left', marginTop: '30px',  paddingRight:'20px' }}>
      
         <Typography variant='h1' sx={{color:'#000', fontWeight: '700'}}>More</Typography>
        
      </Grid>
      <Grid container columns={2} direction="row" justifyContent="flex-start"  className='gridLinkList'>
        <Grid container direction="column" xs={10} lg={4} justifyContent="flex-start" alignItems="flex-start" className='linkList' sx={{ textAlign: 'left' }}>
            <Link underline="none" target="_blank" href="https://manual.mylio.com/">How-To Guide</Link>
            <Link underline="none" target="_blank" href="https://community.mylio.com/">Join Our Community </Link>
            <Link underline="none" target="_blank" href="https://community.mylio.com/spaces/12360024/content">Mylio Photos Fundamentals Course</Link>
            <Link underline="none" target="_blank" href="http://mylio.com/readysetgo">Ready, Set, Go! (new customer onboarding) </Link>
            <Link underline="none" target="_blank" href="https://mylio.link/06012">Learn from Experts </Link>
            
        </Grid>

        <Grid container direction="column" xs={2} lg={4} className='linkList'>
            <Link underline="none" target="_blank" href="https://manual.mylio.com/"> <OpenInNewOutlinedIcon /></Link>
            <Link underline="none" target="_blank" href="https://community.mylio.com/"> <OpenInNewOutlinedIcon /></Link>
            <Link underline="none" target="_blank" href="https://community.mylio.com/spaces/12360024/content"> <OpenInNewOutlinedIcon /></Link>
            <Link underline="none" target="_blank" href="http://mylio.com/readysetgo"> <OpenInNewOutlinedIcon /></Link>
            <Link underline="none" target="_blank" href="https://mylio.link/06012"> <OpenInNewOutlinedIcon /></Link>

        </Grid>
      </Grid>
     
    </Stack>
    
  </Stack >
  </>
    )
}
export default LearnMore