import { Box, Button, Card, CardContent, Container, Divider, Grid, Paper, Stack, Typography, styled, useMediaQuery } from '@mui/material'
import { useStyles } from '@styles'
import mylioLogo from '@assets/Mylio Photos+.png'
import imgOne from '@assets/unsplash_LQ1t-8Ms5PY.png'
import imgTwo from '@assets/universal-library_image.png'
import imgThree from '@assets/screenshot_mylio.png'
import timelineImage from '@assets/timeline-image.png'
import storageImage from '@assets/storage-image.png'
import findCommandImage from '@assets/find-command.png'
import editingToolbarImage from '@assets/editing-toolbar.png'
import learnPanelImage from '@assets/learn-panel-image.png'
import spacesImage from '@assets/spaces-image.png'
import workflowImage from '@assets/workflow-image.png'
import familyHistoryImage from '@assets/family-history-image.png'
import frameProjectImage from '@assets/frame-project-image.png'
import viewbugImage from '@assets/viewbug-image.png'
import safeDeleteImage from '@assets/safe-delete.png'
import quickFilterImage from '@assets/quickFilters-image.png'
import { useEffect } from 'react';


const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Concierge = () => {

  const isBig = useMediaQuery(`(min-width:700px)`)
  const classes = useStyles()

  useEffect(() => {
    document.body.classList.add('news-layout')
    return () => {
      document.body.classList.remove('news-layout')
    }
  }, [])


  const profileCard = (
    <>
      <CardContent sx={{ backgroundColor: "#f5f5f5", width: isBig ? "300px" : "67vw", padding: "20px", borderRadius: "10px", justifyContent: "center", textAlign: "center" }}>
        <img
          src={imgOne}
          alt="placeholder image"
        />

        <Typography variant="h5" component="div" color="#000" fontWeight={'600'} sx={{ mb: 2, mt: 1.5 }}>Concierge</Typography>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 2 }} className='tag-container'>
          <Typography sx={{ mb: 1.5, backgroundColor:'#F9FAFD' }} className={classes.tags} fontSize={'13px'}>Small Bussinesses</Typography>
          <Typography sx={{ mb: 1.5, backgroundColor:'#F9FAFD' }} className={classes.tags} fontSize={'13px'}>Hobbyist</Typography>
        </Stack>

        <Typography variant="body2" color="#000">
          For those who don’t have time to do it themselves or seek mentorship on their journey.
        </Typography>
      </CardContent>
  
    </>
  );
  
  const serviceDescription = (
    <>
      <CardContent>
        {/* <Typography sx={{ fontSize: isBig? '34px' : '21px', fontWeight: 'bold' }} color="#000" gutterBottom>
          One-on-One Services
        </Typography> */}
        <Typography variant="h5" component="div" color="#000"  sx={{ fontSize: '16px', mt:3.5, mb:3 }}>
          Three one-hour sessions | $280 <span style={{textDecoration: 'line-through'}}>$450</span>
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="#000">
          We have a network of Photo Managers with Varying specialties such as:
        </Typography>
        <Stack direction='row' spacing={3} color="#000" className='tag-container' sx={{ display: 'inline' }}>
          <Typography className={classes.tags}>Cataloging scanned media</Typography>
          <Typography  className={classes.tags}>Mixed media libraries</Typography>
          <Typography  className={classes.tags}>Customized syncing</Typography>
          <Typography  className={classes.tags}>Shared accounts for work or family</Typography>
          <Typography  className={classes.tags}>Multi-layered backups</Typography>
          <Typography  className={classes.tags}>Small businesses</Typography>
        </Stack>
      </CardContent>
  
    </>
  );


 return (
  
  <>
  <Stack sx={{ height:'100vh', py: isBig ? `1vh` : `6vh`, paddingLeft: isBig? `0`:`92px`, paddingRight: isBig? `0`:`74px`, overflow: `auto`}} >
  <Typography sx={{ fontWeight: '700', marginBottom: '30px', marginTop:'40px' }} color="#000" variant='h4'>
          One-on-One Services
        </Typography>
    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 2, lg:4 }} sx={{ px: isBig? `13vw` : `0vw` }}>
        
        <Stack>
          {profileCard}
        </Stack>
        <Stack sx={{ paddingLeft: isBig? `60px` : `0`  }}>
            {serviceDescription}
        </Stack>
    </Stack>

    <Stack direction="column" justifyContent={`center`} sx={{ px: isBig? `13vw` : `0vw`, margin: '0 auto', paddingBottom: isBig ? '60px' : '0px'  }}>

        <Typography sx={{ mb: 1.5, mt: 10, fontSize: '40px', fontWeight: 'bold' }} color="#000" variant='h3'>
          What can they help you with?
        </Typography>
        
        <Typography sx={{ mb: 5, fontSize: '24px', fontWeight: '700' }} color="#000" variant='h1'>
          Some of the things our Photo Managers specialize in
        </Typography>

        <Stack direction={{ xs: 'column', sm: 'row' }} textAlign={'left'}>

          <Stack justifyContent={'center'} spacing={{sm: 1, md:4}} sx={{ paddingLeft: isBig? '0px' : '0px' }}>
            <Typography sx={{ fontWeight: '700' }} color="#000" variant='body1'>
              Catalog scanned media
            </Typography>
            <Typography sx={{ mb: 3, mt: '0px !important'  }} color="#000" variant='body1'>
            Catalog scanned media for easy browsing and searching, integrated with your digital media.
            </Typography>

            <Typography sx={{ fontWeight: '700' }} color="#000" variant='body1'>
              Customized syncing
            </Typography>
            <Typography sx={{ mb: 3, mt: '0px !important'  }} color="#000" variant='body1'>
            Configure syncing for each device, ensuring you only have the desired content on each one.
            </Typography>


            <Typography  sx={{  fontWeight: '700',  }} color="#000" variant='body1'>
              Multi-layered backups
            </Typography>
            <Typography sx={{ mb: 3, mt: '0px !important'  }} color="#000" variant='body1'> 
              Establish a multi-layered backup process that includes hard drives, NAS, computers, tablets, smartphones, and cloud accounts.
            </Typography>
          </Stack>

          <Stack sx={{ paddingTop: '20px', paddingLeft: isBig ? '60px' : '0px', paddingRight: isBig ? '60px' : '0px' }}>
            <img
              src={imgTwo}
              alt="placeholder image"
              width={'100%'}
            />
          </Stack>

        </Stack>

        

   
     

      </Stack> 


      <Stack direction={{ xs: 'column', sm: 'column' }} justifyContent={`center`} sx={{ px: isBig? `13vw` : `0vw` , margin: '0 auto' }}>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing="2" textAlign={'left'} >

          <Stack sx={{ paddingTop: '60px', paddingRight: isBig ? '80px': '0' }}>
            <img
              src={imgThree}
              alt="placeholder image"
              width={'100%'}
            />
          </Stack>

          <Stack justifyContent={'center'}>
            <Typography sx={{ fontWeight: '700' }} color="#000" variant='body1'>
              Mixed-media libraries
            </Typography>
            <Typography sx={{ mb: 3 }} color="#000" variant='body1'>
            Create a seamless system for cataloging and searching across various file types.
            </Typography>

            <Typography sx={{ fontWeight: '700' }} color="#000" variant='body1'>
              Shared accounts
            </Typography>
            <Typography sx={{ mb: 3  }} color="#000" variant='body1'>
            Setup multiple accounts for collaboration with family, friends, or colleagues. Control over what you share.
            </Typography>

            <Typography sx={{ fontWeight: '700' }} color="#000" variant='body1'>
              Small businesses
            </Typography>
            <Typography sx={{ mb: 3 }} color="#000" variant='body1'>
            Businesses that handle a significant amount of media. Typically for creative, marketing, collaboration, or archival purposes.
            </Typography>
          </Stack>

      </Stack>

    </Stack> 

      <Stack direction="column" justifyContent={`center`} sx={{  margin: '0 auto', backgroundColor: '#FBFCFE', mb:6, mt:6 }}>
        <Typography sx={{ mb: 1.5, mt:5, fontSize: '34px', fontWeight: '700', textAlign: 'left', px: isBig? `13vw` : `0vw` }} color="#000" variant='h1' >
          What should you expect?
        </Typography>
        
          <Stack sx={{ paddingLeft:isBig? '65px' : '0', py: isBig? '50px' : '0'}}>
            <img
              src={timelineImage}
              alt="placeholder image"
              width={'100%'}
            />
          </Stack>
        

      </Stack>



      <Typography sx={{ mb: 3, mt:5, fontSize: '34px', fontWeight: '700', textAlign: 'left', pl: isBig? `13vw` : `0vw` }} color="#000" variant='h1'>
        Want to learn more before commiting?
      </Typography>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{xs:1, sm:1, lg:2}} textAlign={"left"} sx={{ px: isBig? `10vw` : `0`}}>
        
        <Stack sx={{ padding: isBig? '50px': '10px' }}>
          <Typography sx={{ mb: 1.5, fontWeight: '700' }} color="#000" variant='h5'>
            Chat with us          
          </Typography>
          <Typography variant='body1' color="#000" sx={{ mb:3, mt:.5 }} >
            Connect with a live member of our team through the chat bubble at the bottom of your browser window. 
          </Typography>
          <Button variant="outlined" target='_blank' sx={{ color: "#0E9AF1", borderColor:"#0E9AF1", backgroundColor: "#fff", maxWidth: '200px' }} href="#">Chat Now</Button>
        </Stack>

        <Stack sx={{ padding: isBig? '50px': '10px' }}>
          <Typography sx={{ mb: 1.5, fontWeight: '700' }} color="#000" variant='h5'>
            Use Mylio Photos today         
          </Typography>
          <Typography variant='body1' color="#000" sx={{ mb:3, mt:.5 }}>
            All yearly subscriptions come with a free 30 minute set up session. See how that works for you before trying out more. 
          </Typography>
          <Button variant="outlined" target='_blank' sx={{ color: "#0E9AF1", borderColor:"#0E9AF1", backgroundColor: "#fff", maxWidth: '200px' }} href="#">Subscribe</Button> 
        </Stack>
      </Stack>

  </Stack>
  </>
 )
}
export default Concierge