import { createTheme, CSSInterpolation } from "@mui/material"
import { grey, yellow } from "@mui/material/colors"

export const theme = createTheme({
  palette: {
    mode: `dark`,
    primary: {
      light: `#0E9AF1`,
      main: `#0E9AF1`,
      dark: `#1088D2`,
      contrastText: `#FFF`,
    },
    error: {
      light: `#E01E5A`,
      main: `#E01E5A`,
      dark: `#B91E50`,
    },
    warning: {
      light: yellow[700],
      main: yellow[700],
      dark: yellow[800],
    },
    info: {
      light: grey[300],
      main: grey[300],
      dark: grey[400],
    },
    
  },
  shape: {
    borderRadius: 10,
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: () => {
          return { fontSize: `32px` }
        },
        h2: () => {
          return { fontSize: `22px` }
        },
        h5: () => {
          return { fontSize: `20px` }
        }
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: () => {
          return { backgroundColor: `#fff`, padding: `0` }
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: `contained`,
      },
      styleOverrides: {
        root: ({ ownerState }) => {
          const { color, disabled, variant } = ownerState
          const buttonStyle: CSSInterpolation = { borderRadius: `5px`, whiteSpace: `nowrap` }
          if (variant === `contained` || variant === `outlined`) {
            buttonStyle.fontSize = `14px`
            buttonStyle.fontWeight = 600
            buttonStyle.padding = `10px 40px`
            buttonStyle.backgroundColor = `#0090E1`
            buttonStyle[`:hover`] = { color: color !== `info` ? `#FFF` : `#000` }
            if (disabled) {
              if (variant === `contained`) {
                buttonStyle.backgroundColor = `#16181D !important`
                buttonStyle.color = `#ffffff !important`
              }
            }
          }
          return buttonStyle
        },
      },
    },
    MuiCard: {
      defaultProps: {
        raised: true,
      },
      styleOverrides: {
        root: () => {
          return {
            backgroundColor: `#fff`,
            boxShadow: `none`,
            color: `#000 !important`,
            padding: `0`,
          }
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: () => {
          return {
            textAlign: `left`,
          }
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: () => {
          return {
            textAlign: `left`,
            fontSize: `18px !important`,
            padding: `20px 0px`
          }
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: () => {
          return {
            p: 2,
            color: `white`
          }
        },
        paper: () => {
          return {
            background: `#071B40`,
            color: `white`,
          }
        },
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: () => {
          return {
            background: `rgba(255,255,255,0.5)`
          }
        }
      },
    },
    MuiGrid: {
      defaultProps: {
        justifyContent: `center`,
        alignItems: `center`,
        marginLeft: `0`,
        width: `100%`

      },
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: () => {
          return {
            fontWeight: 400
          }
        },
        shrink: () => {
          return {
            color: `rgb(128, 128, 128) !important`
          }
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: () => {
          return { color: `rgba(255,255,255,0.25)` }
        },
      },
    },
    MuiNativeSelect: {
      styleOverrides: {
        root: () => {
          return { borderRadius: `4px` }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        filled: () => {
          return { borderRadius: `4px` }
        },
        select: () => {
          return {
            background: `white`
          }
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: `filled`
      },
      styleOverrides: {
        root: () => {
          return {
            background: `white`,
            borderRadius: `10px`,
            color: `black`
          }
        }
      }
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          boxSizing: `content-box`,
          padding: `3`,
          fontSize: `1.125rem`,
          backgroundColor: `#16181D`
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: `#16181D`,
          fontSize: `18px`
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: `0`, 
          backgroundColor: `#fff`
        },
      },
    }
  }  
})
