import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';

const Help = () => {

    const {
        boot,
        showSpace,
        showNewMessage
      } = useIntercom();

    useEffect(() => {
    document.body.classList.add('chat-layout')
    return () => {
        document.body.classList.remove('chat-layout')
    }
    }, [])
      
      
    return (
        
        <>
        
            {/* {boot()} */}
            {showNewMessage('')}
            
        </>
    )
}
export default Help