import Cookies from 'universal-cookie'
import logging from '@logging'

export const toCamelCase = (str: string) => str.replace(/(?:^\w|[A-Z]|\b\w)/g, (ltr, idx) => idx === 0 ? ltr.toLowerCase() : ltr.toUpperCase()).replace(/\s+/g, ``)
export const toCapCamelCase = (str: string) => str.replace(/(?:^\w|[A-Z]|\b\w)/g, ltr => ltr.toUpperCase()).replace(/\s+/g, ``)
export const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)
export const containsUpperCase = (str: string) => /[A-Z]/.test(str)
export const containsLowerCase = (str: string) => /[a-z]/.test(str)
export const containsSpecialCharacter = (str: string) => /[~`!@#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(str)
export const containsNumber = (str: string) => /\d/.test(str)
export const replaceUnderscores = (str: string) => str.replace(/_/g, ` `)
export const convertTime = (utcSeconds: number) => new Date(0).setUTCSeconds(utcSeconds).toLocaleString()

// String to ArrayBuffer
export function str2ab(str: string) {
  const buf = new ArrayBuffer(str.length * 2) // 2 bytes for each char
  const bufView = new Uint16Array(buf)
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i)
  }
  return buf
}

export const isLocalhost = () => window.location.hostname == `localhost`

export const isWin = () => navigator.userAgent.includes(`Windows`)
export const isMacOS = () => navigator.userAgent.includes(`Macintosh`)
export const isAndroid = () => navigator.userAgent.includes(`Android`)
export const isIOS = () => {
  if (/iPad|iPhone|iPod/i.test(navigator.userAgent)) return true

  return (
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2 &&
    /MacIntel/.test(navigator.userAgent)
  )
}

export const hasURLParam = (param: string) => new URLSearchParams(window.location.search).has(param) && new URLSearchParams(window.location.search).get(param) !== ``
export const getURLParam = (param: string) => new URLSearchParams(window.location.search).get(param)
export const deleteURLParam = (param: string) => new URLSearchParams(window.location.search).delete(param)

export const setCookie = (aid: string, rToken: string) => {
  const cookies = new Cookies()
  cookies.remove(`userCookie`)
  // Days * Hours * Minutes * Seconds * Milliseconds
  const cookieMaxAge = (48 * 60 * 60 * 1000) // 48 hours
  const d = new Date()
  d.setTime(d.getTime() + cookieMaxAge)
  const cookieObj = { aid, rToken }
  logging.info(cookieObj)
  cookies.set(`userCookie`, cookieObj, { path: `/`, expires: d })
}
