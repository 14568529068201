import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';

const KnowledgeBase = () => {

    const {
        boot,
        showSpace
      } = useIntercom();

    useEffect(() => {
    document.body.classList.add('help-layout')
    return () => {
        document.body.classList.remove('help-layout')
    }
    }, [])  

    return (
        <>
            {/* {boot()} */}
            {showSpace('help')}
        </>
    )
}
export default KnowledgeBase