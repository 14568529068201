import { Grid, Stack, Typography, styled } from '@mui/material'
import MuiAccordionSummary, { AccordionSummaryProps} from '@mui/material/AccordionSummary';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { useStyles } from '@styles'
import strings from '@json/strings'
import React from 'react'

const StartHere = () => {
  // const isBig = useMediaQuery(`(min-width:1500px)`)
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState<string | false>('panel1');

  const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));
  
  const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
      textAlign: `left`
    },
    "& .Mui-expanded": {
      color: `#0e9af1`
    },
    "MuiAccordionSummary-expandIconWrapper":{
      color: `#0e9af1`
    }
  }));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));


  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  return (
    <Stack className={classes.gettingStartedView} sx={{ pt: `12vh`, overflow: `auto` }} justifyContent={`top`} >
      <Grid container columnSpacing='8vw' rowSpacing={12} sx={{ paddingBottom: 3, paddingTop:2}}>
        {/* How to section */}
        <Typography variant="h2">{strings.views.howToContent.header}</Typography>
        <Stack sx={{ paddingTop: 3}}>
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
              <Typography>{strings.views.howToContent.addFilesHeader}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionContent}>
              The first time you open Mylio Photos, you’ll see a guided import window that lets you check off different media sources you may want to import into your Mylio Photos Library. Add a few sources, then click the I’m done adding media for now button. You can always add additional photos and videos later by clicking the plus icon  to Add media or by clicking the File menu in the operating system menu bar and choosing Guided Import.
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
              <Typography>{strings.views.howToContent.filterSmarttagHeader}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionContent}>
              Mylio Photos' QuickFilters tool helps you quickly find specific photos, videos, and documents by narrowing your browsing results based on specific criteria. QuickFilters can be used in any view with options to filter by folder, date, event, file type, album, person, category, ratings, flags, labels, cameras, lenses, and more. <br/> <br/> Start by clicking or tapping the funnel icon  in the left sidebar to open the QuickFilters tool. Mylio Photos has several QuickFilters to choose from. Each view (like Folders and People) has default options enabled, but you can customize your results by clicking the gear icon  on the QuickFilters title bar.
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
              <Typography>{strings.views.howToContent.editPhotosHeader}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionContent}>
              Mylio Photos contains essential editing tools that work equally well on your computer and mobile devices. It also offers the ability to connect with other editing tools you might desire to use such as Adobe Lightroom Classic, Affinity Photo, and many others.
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
            <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
              <Typography>{strings.views.howToContent.shareExportHeader}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionContent}>
              Mylio Photos contains essential editing tools that work equally well on your computer and mobile devices. It also offers the ability to connect with other editing tools you might desire to use such as Adobe Lightroom Classic, Affinity Photo, and many others.
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
            <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
              <Typography>{strings.views.howToContent.shareExportHeader}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionContent}>
              Keeping your photos organized in Mylio Photos is great, but photos are meant to be shared! Mylio Photos offers several ways to export your photos so you can share them via email, social media, create prints, and more.
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
            <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
              <Typography>{strings.views.howToContent.backupHeader}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionContent}>
                Creating a solid backup plan is crucial in protecting your precious photo and video memories. Data experts generally recommend using a 3-2-1 backup strategy:
                <ul>
                  <li>3 copies of your data</li>
                  <li>2 local copies on different storage devices</li>
                  <li>1 off-site copy (usually a cloud service or storage device)</li>
                </ul>
                Mylio Photos+ makes it easy to protect your photos and videos and ensure your memories are safe.        
            </AccordionDetails>
          </Accordion>
        </Stack>



        <Typography 
          variant="h2" 
          sx={{paddingTop:3}} 
          className={classes.faqHeader}>
          {strings.views.faqContent.faqHeader}
        </Typography>
        <Stack sx={{ paddingTop: 3}}>
          <Accordion expanded={expanded === 'panel21'} onChange={handleChange('panel21')}>
            <AccordionSummary aria-controls="panel21d-content" id="panel21d-header">
              <Typography>{strings.views.faqContent.faqQuestionOne}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionContent}>
              If you are a Mylio Photos+ subscriber, and you’ve set up a vault, recovery is fully automatic.  Simply sign in on the new device or plug in a new hard drive and add it to your library.  Mylio Photos+ automatically creates backup copies of your photos on your devices and vault drives, ensuring you can still access and recover them from another device.

              This peace of mind is the primary benefit of Mylio Photos+ as you know that your precious photos and videos are protected against device loss or disk failure.

              Not a Mylio Photos+ user?  Well, you can always explore the path of disk recovery for a failed disk.  Most services charge several thousand dollars to recover data.  If you lose a device, you’re probably out of luck.
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel22'} onChange={handleChange('panel22')}>
            <AccordionSummary aria-controls="panel22d-content" id="panel22d-header">
              <Typography>{strings.views.faqContent.faqQuestionTwo}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionContent}>
              The free Mylio Photos application works with the built-in storage on your device and any directly attached hard drives.

              With a Mylio Photos+ plan, you can choose to create backup copies on your devices and backup drives. Mylio Photos+ offers cloud independence, so you can access all your photos from any device without relying on the cloud.
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel23'} onChange={handleChange('panel23')}>
            <AccordionSummary aria-controls="panel23d-content" id="panel23d-header">
              <Typography>{strings.views.faqContent.faqQuestionThree}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionContent}>
              Mylio Photos does not store your information on the web. Photos and videos are kept only on your local devices. This means that your photos are accessible from the Mylio Photos application — not from the web. The way our app works provides you with unlimited storage (based on the capacity of your storage devices) and more privacy.

              While you can log in to the Mylio Photos website, the only actions you can take there are to manage your account, manage your devices, or download the Mylio Photos application.
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel24'} onChange={handleChange('panel24')}>
            <AccordionSummary aria-controls="panel24d-content" id="panel24d-header">
              <Typography>{strings.views.faqContent.faqQuestionFour}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionContent}>
              If you cancel your Mylio Photos+ subscription, your subscription will stop renewing. However, you'll still have access to all the benefits of Mylio Photos+ until the end of the period you've already paid for.

              All of your photos are still stored on your vault drives or devices and are accessible via your computer’s operating system.

              Additionally, any locally synced files are still available on that device.

              All metadata and information added to photos is in an industry-standard XMP format and can be read by many other applications.

              You can still use the free Mylio Photos application to browse and enjoy your library – but syncing and protection is disabled.
            </AccordionDetails>
          </Accordion>
        </Stack>



      </Grid>
    </Stack >
    
  )
}
export default StartHere