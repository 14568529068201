import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import MessageIcon from '@mui/icons-material/Message';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import InfoIcon from '@mui/icons-material/Info';
import { Link, Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import CampaignIcon from '@mui/icons-material/Campaign';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
 
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  // zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);


export default function BottomNav() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    
    <Box sx={{ display: 'flex' }}>
       
      <CssBaseline />
      
      <Drawer variant="permanent" open={open} className="sidebar" sx={{ "& .Mui-selected, & .Mui-selected > span, & .navLinkLabel:active": {color: '#0090E1'}, "zIndex": 1500 }}>
        <Link href='/?tab=1' sx={{ textDecoration: `none` }} >
          
            <ListItemButton sx={{ color: "white", paddingLeft: "16px", paddingTop: "30px", marginBottom: "10px" }} >
              <Tooltip title="Start Guide" placement="right">
                <ListItemIcon sx={{ color: "white" }} className='info-icon'> 
                  {<InfoIcon />} 
                </ListItemIcon> 
              </Tooltip>
              <ListItemText className='navLinkLabel-start' primary={"Start Guide"} /> 
            </ListItemButton> 
          
          </Link>
       
        
          <Link href='/chat?tab=2' sx={{ textDecoration: `none` }}>
            <Tooltip title="Chat" placement="right">
              <ListItemButton sx={{ color: "white", marginBottom: "10px", paddingLeft: "16px !important" }}> 
                <ListItemIcon sx={{ color: "white" }} className='chat-icon'> 
                  {<MessageIcon />} 
                </ListItemIcon> 
                <ListItemText className='navLinkLabel-chat' primary={"Chat"} /> 
              </ListItemButton>
            </Tooltip>
          </Link>
       
        
          <Link href='/learn?tab=3' sx={{ textDecoration: `none` }}>
            <Tooltip title="Help" placement="right">
              <ListItemButton sx={{ color: "white", marginBottom: "10px", paddingLeft: "16px !important" }}> 
              <ListItemIcon 
                  sx={{ color: "white" }} 
                  className='icon-Support_Chat_Help_Fill'>
                </ListItemIcon> 
                <ListItemText className='navLinkLabel-help' primary={"Help"} /> 
              </ListItemButton>
            </Tooltip>
          </Link>
        

        <Link href='/news?tab=4' sx={{ textDecoration: `none` }}>
          <ListItemButton sx={{ color: "white", marginBottom: "10px", paddingLeft: "16px !important" }}> 
            <Tooltip title="News" placement="right">
              <ListItemIcon className='news-icon'>
                {<CampaignIcon />}
              </ListItemIcon> 
            </Tooltip>
            <ListItemText primary={"What's new"} /> 
          </ListItemButton> 
        </Link>

          <Link href='/plans?tab=5' sx={{ textDecoration: `none` }}>
            <ListItemButton sx={{ color: "white", marginBottom: "10px", paddingLeft: "16px !important" }}> 
              <Tooltip title="Plans" placement="right">
                <ListItemIcon 
                  sx={{ color: "white" }} 
                  className='icon-plans'>
                </ListItemIcon> 
              </Tooltip>
              <ListItemText className='navLinkLabel-plans' primary={"Plans"} /> 
            </ListItemButton> 
          </Link>
        



          <Link href='/learnmore?tab=6' sx={{ textDecoration: `none` }}>
            <Tooltip title="Learn More" placement="right">
              <ListItemButton sx={{ color: "white", marginBottom: "10px", paddingLeft: "16px !important" }}> 
                
                  <ListItemIcon sx={{ color: "white" }} className='more-icon'> 
                    {<FormatListBulletedIcon />} 
                  </ListItemIcon> 
                
                <ListItemText className='navLinkLabel-more' primary={"More"} /> 
              </ListItemButton> 
            </Tooltip>
          </Link>
        

          
        <IconButton
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            bottom: '0',
            position: 'absolute',
            marginLeft: '12px !important',
            marginBottom: '10px !important',
            ...(open && { display: 'none', color: 'Highlight' }),
          }}
        >
          <KeyboardDoubleArrowRightIcon /> 
        </IconButton>
        
        <IconButton 
        aria-label="close drawer"
          onClick={handleDrawerClose}
          sx={{
            bottom: '0',
            position: 'absolute',
            marginLeft: '12px !important',
            marginBottom: '10px !important',
            ...(!open && { display: 'none'}),
          }}>
          <KeyboardDoubleArrowLeftIcon /> 
        </IconButton>
      </Drawer>
    </Box>
  );
}