/*
  Filename: app.tsx
  Note: app.tsx is the main App component of this react project.
  All modules and sub-views are imported to this main component.
*/

// Utilities
import logging from '@logging'
import { theme } from '@themes'
import { useStyles } from '@styles'

// React Components
import { useEffect, useRef } from 'react'

// MUI Components
import { Box, CssBaseline, Grid, ThemeProvider } from '@mui/material'

//Intercom
import { IntercomProvider } from 'react-use-intercom'

//Custom Components
import StartHereOnline from './views/startHereOnline';
import StartHereOffline from './views/startHereOffline';
import Chat from './views/help';
import Plans from './views/plans';
import Learn from './views/learn'
import Error from './views/error'
import Community from './views/community'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import LearnMore from './views/learnMore'
import SideNav from './components/sideNav'
import News from './views/news'
import Concierge from './views/concierge'



const App = () => {
  const config = require(`@json/${window.location.hostname}.json`) // <- if you need to use config jsons this is the object

  const componentDidMount = () => {
    if (config.debug) {
      // Example use of config object - can be removed if not needed
      logging.info(`Application mounted`)
    }
  }

  useEffect(() => { componentDidMount() }, [])

  //Intercom App ID
  const INTERCOM_APP_ID = "yut4e4mj";

  return (
   <>
   
    <Box className={useStyles().app} id='App'>
        <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={true}>
          <ThemeProvider theme={theme} >
            <CssBaseline />   
            <Grid>
              <Router>
                  <Routes>
                      <Route path="/" Component={StartHereOnline} />
                      <Route path="/offline" Component={StartHereOffline} />
                      <Route path="/chat" Component={Chat} />
                      <Route path="/plans" Component={Plans} />
                      <Route path="/learn" Component={Learn} />
                      <Route path="/news" Component={News} />
                      <Route path="/error" Component={Error} />
                      <Route path="/community" Component={Community} />
                      <Route path="/concierge" Component={Concierge} />
                      <Route path="/learnmore" Component={LearnMore} />
                      <Route path="/?tab=1" Component={StartHereOnline} />
                      <Route path="/chat?tab=2" Component={Chat} />
                      <Route path="/learn?tab=3" Component={Learn} />
                      <Route path="/news?tab=5" Component={News} />
                      <Route path="/plans?tab=4" Component={Plans} />
                      <Route path="/learnmore?tab=6" Component={LearnMore} />
                  </Routes>
                </Router> 
            </Grid>
              
            <SideNav />

          </ThemeProvider>
        </IntercomProvider>
      
    </Box>
    
    </>
  )
}

export default App
