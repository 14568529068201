import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles({
  app: {
    width: `100vw`,
    height: `100vh`,
    fontFamily: `"Open Sans", sans-serif`,
    textAlign: `center`,
    background: `#fff`
  },
  logoContainer: {
    backgroundColor: `#000 !important`,
    width: `100% !important`
  },
  contentContainer: {
    width: `100%`,
    color: `#fff`,
    padding: `30px !important`
  },
  gettingStartedView: {
    height: `100vh`,
    paddingTop: `0 !important`,
    paddingBottom: `0 !important`,
    marginLeft: `57px`,
    paddingRight: `0 !important`
  },
  pageHeader: {
    fontFamily: `"Open Sans", sans-serif`,
    fontSize:`28px !important`,
    margin: `15px 30px`,
    paddingTop: `35px`,
    paddingLeft: `30px`,
    backgroundColor: `#000`,
    width: `100% !important`
  },
  headerLink: {
    "&:hover": {
      color: `#0e9af1 !important`,
    }
  },
  videoPlayerWrapper: {
    position: `relative`
  },
  reactPlayer: {
    position: `relative`,
    top: `0`,
    left: `0`
  },
  accordionContent: {
   textAlign: `left`,
  }, 
  faqHeader: {
    fontSize: `22px`
  },
  onlineStatus: {
    height: `55px`,
    textAlign: `right`,
    borderRadius: `0`,
    fontSize:  `18px`,
    textTransform: `capitalize`,
    backgroundColor: `#16181D`,
    width: `100%`
  },
  onlineIcon: {
    color: `#35a78c !important`,
    fontSize: `22px`,
    height: `45px`
  },
  offlineIcon: {
    fontSize: `22px`,
    color: `#aaa !important`,
    height: `45px`
  }, 
  mediaCard: {
    marginBottom: `20px`
  },
  startGuideHeader: {
    textAlign: `left`, 
    fontSize: `24px !important`
  },
  startGuideContent: {
    fontSize: `18px !important`
  },
  placeholderImg: {
    width: `100%`
  },
  tags: {
    padding: `10px 20px`,
    color: `#000 !important`,
    backgroundColor: `#F5f5f5`,
    borderRadius: `16px`,
    border: `none`,
    textAlign: `center`,
    textDecoration: `none`,
    display: `inline-block`,
    margin: `4px 2px`,
    cursor: `pointer`,
    fontSize: `14px`
  }
})
